import AgradecimientoContacto from "../../pages/AgradecimientoContacto/AgradecimientoContacto";
import AltaCripto from "../../pages/Crypto/AltaCripto/AltaCripto";
import AprobacionReconocimiento from "../../pages/Reconocimientos/Aprobación/AprobacionReconocimiento";
import CarritoPage from "../../pages/CarritoPage/CarritoPage";
import { ComprarCrypto } from "../../pages/Crypto/ComprarCrypto/ComprarCrypto";
import Contacto from "../../pages/Contacto/Contacto";
import Crypto from "../../pages/Crypto/Crypto";
import EducacionFinanciera from "../../pages/EducacionFinanciera/EducacionFinanciera";
import Catalogo from "../../pages/Catalogo/Catalogo";
import MiEquipo from "../../pages/MiEquipo/MiEquipo";
import MisDatos from "../../pages/MisDatos/MisDatos";
import MisMovimientos from "../../pages/MisMovimientos/MisMovimientos";
import Producto from "../../pages/Producto/Producto";
import ReconocimientoAsignado from "../../pages/Reconocimientos/MisReconocimientos/MisReconocimientos";
import Reconocimientos from "../../pages/Reconocimientos/Reconocimiento/Reconocimiento";
import ReconocimientosRealizados from "../../pages/Reconocimientos/Realizados/ReconocimientosRealizados";
import VenderCrypto from "../../pages/Crypto/VenderCrypto/VenderCrypto";
import { PERMISOS_CODIGOS } from "./constants";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import SportsSoccerOutlinedIcon from "@mui/icons-material/SportsSoccerOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";

export const RUTAS = {
  home: "/",
  login: "/login",
  catalogo: "/catalogo",
  contacto: "/contacto",
  producto: "/producto/:id",
  agradecimiento: "/agradecimiento",
  cripto: "/crypto",
  criptoAlta: "/crypto/alta",
  criptoAltaStep2: "/crypto/alta/step2",
  criptoAltaStep3: "/crypto/alta/step3",
  compraCripto: "/crypto/compra",
  ventaCripto: "/crypto/venta",
  carrito: "/carrito",
  reconocimiento: "/reconocimiento/reconocimiento",
  reconocimientoAprobacion: "/reconocimiento/aprobacion",
  misReconocimientos: "/reconocimiento/realizados",
  reconocimientoAsignado: "/reconocimiento/recibidos",
  miEquipo: "/reconocimiento/equipo",
  misMovimientos: "/misMovimientos",
  educacion: "/educacion",
  misDatos: "/misDatos",
  copa: "http://copa.adviters.com/",
};

export const RECONOCIMIENTO_SUB_MENU = [
  {
    ruta: RUTAS.reconocimiento,
    dictionario: "reconocimientos",
    codigo: PERMISOS_CODIGOS.generarReconocimiento,
  },
  {
    ruta: RUTAS.reconocimientoAprobacion,
    dictionario: "aprobacionReconocimientos",
    codigo: PERMISOS_CODIGOS.aprobacionReconocimientos,
  },
  {
    ruta: RUTAS.misReconocimientos,
    dictionario: "reconocimientosRealizados",
    codigo: PERMISOS_CODIGOS.veMisReconocimientos,
  },
  {
    ruta: RUTAS.reconocimientoAsignado,
    dictionario: "reconocimientoAsignado",
    codigo: PERMISOS_CODIGOS.verMiEquipo,
  },
  {
    ruta: RUTAS.miEquipo,
    dictionario: "miEquipo",
    codigo: PERMISOS_CODIGOS.verMiEquipo,
  },
];

export const CRIPTO_SUB_MENU =
[  {
    ruta: RUTAS.cripto,
    dictionario: "billetera",
    codigo: PERMISOS_CODIGOS.criptomonedas,
  },
  {
    ruta: RUTAS.educacion,
    dictionario: "educacionFinanciera",
    codigo: PERMISOS_CODIGOS.educacionFinanciera,
  },
]
export const MENU_DRAWER_LOGIN = [
  {
    ruta: RUTAS.home,
    dictionario: "inicio",
    codigo: PERMISOS_CODIGOS.dashboardKiwinEnHome,
    divider: false,
    icon: <HomeOutlinedIcon sx={{ fontSize: "20px", color: "#404040" }} />,
  },
  {
    ruta: RUTAS.catalogo,
    dictionario: "giftCards",
    codigo: PERMISOS_CODIGOS.catalogoGiftCard,
    divider: false,
    icon: <RedeemOutlinedIcon sx={{ fontSize: "20px", color: "#404040" }} />,
  },
  {
    ruta: RUTAS.copa,
    dictionario: "copaKiwin",
    codigo: PERMISOS_CODIGOS.catalogoGiftCard,
    divider: false,
    icon: (
      <SportsSoccerOutlinedIcon sx={{ fontSize: "20px", color: "#404040" }} />
    ),
  },
  {
    ruta: RUTAS.cripto,
    dictionario: "criptomonedas",
    codigo: PERMISOS_CODIGOS.criptomonedas,
    subMenu: CRIPTO_SUB_MENU,
    divider: true,
    icon: (
      <CurrencyBitcoinOutlinedIcon
        sx={{ fontSize: "20px", color: "#404040" }}
      />
    ),
  },
  {
    ruta: "",
    dictionario: "reconocimientos",
    codigo: PERMISOS_CODIGOS.reconocimientosEnDashboardWeb,
    divider: false,
    subMenu: RECONOCIMIENTO_SUB_MENU,
    icon: (
      <EmojiEventsOutlinedIcon sx={{ fontSize: "20px", color: "#404040" }} />
    ),
  },
  {
    ruta: RUTAS.misMovimientos,
    dictionario: "misMovimientos",
    codigo: PERMISOS_CODIGOS.misMovimientos,
    divider: false,
    icon: <ChecklistOutlinedIcon sx={{ fontSize: "20px", color: "#404040" }} />,
  },
  {
    ruta: RUTAS.misDatos,
    dictionario: "misDatos",
    codigo: PERMISOS_CODIGOS.misDatos,
    divider: false,
    icon: (
      <ContactPageOutlinedIcon sx={{ fontSize: "20px", color: "#404040" }} />
    ),
  },
  {
    ruta: RUTAS.contacto,
    dictionario: "contactanos",
    codigo: PERMISOS_CODIGOS.contactanos,
    divider: false,
    icon: (
      <PhoneInTalkOutlinedIcon sx={{ fontSize: "20px", color: "#404040" }} />
    ),
  },
];

export const LOGGED_ROUTER_DOM_ROUTES = [
  {
    ruta: RUTAS.catalogo,
    codigo: PERMISOS_CODIGOS.catalogoGiftCard,
    componente: Catalogo,
  },
  {
    ruta: RUTAS.producto,
    codigo: PERMISOS_CODIGOS.catalogoGiftCard,
    componente: Producto,
  },
  {
    ruta: RUTAS.carrito,
    codigo: PERMISOS_CODIGOS.catalogoGiftCard,
    componente: CarritoPage,
  },
  {
    ruta: RUTAS.contacto,
    codigo: PERMISOS_CODIGOS.contactanos,
    componente: Contacto,
  },
  {
    ruta: RUTAS.agradecimiento,
    codigo: PERMISOS_CODIGOS.contactanos,
    componente: AgradecimientoContacto,
  },
  {
    ruta: RUTAS.misDatos,
    codigo: PERMISOS_CODIGOS.misDatos,
    componente: MisDatos,
  },
  {
    ruta: RUTAS.cripto,
    codigo: PERMISOS_CODIGOS.criptomonedas,
    componente: Crypto,
  },
  {
    ruta: RUTAS.criptoAlta,
    codigo: PERMISOS_CODIGOS.criptomonedas,
    componente: AltaCripto,
  },
  {
    ruta: RUTAS.compraCripto,
    codigo: PERMISOS_CODIGOS.criptomonedas,
    componente: ComprarCrypto,
  },
  {
    ruta: RUTAS.ventaCripto,
    codigo: PERMISOS_CODIGOS.criptomonedas,
    componente: VenderCrypto,
  },
  {
    ruta: RUTAS.reconocimiento,
    codigo: PERMISOS_CODIGOS.generarReconocimiento,
    componente: Reconocimientos,
  },
  {
    ruta: RUTAS.reconocimientoAprobacion,
    codigo: PERMISOS_CODIGOS.aprobacionReconocimientos,
    componente: AprobacionReconocimiento,
  },
  {
    ruta: RUTAS.misReconocimientos,
    codigo: PERMISOS_CODIGOS.veMisReconocimientos,
    componente: ReconocimientosRealizados,
  },
  {
    ruta: RUTAS.reconocimientoAsignado,
    codigo: PERMISOS_CODIGOS.verMiEquipo,
    componente: ReconocimientoAsignado,
  },
  {
    ruta: RUTAS.miEquipo,
    codigo: PERMISOS_CODIGOS.verMiEquipo,
    componente: MiEquipo,
  },
  {
    ruta: RUTAS.misMovimientos,
    codigo: PERMISOS_CODIGOS.misMovimientos,
    componente: MisMovimientos,
  },
  {
    ruta: RUTAS.educacion,
    codigo: PERMISOS_CODIGOS.educacionFinanciera,
    componente: EducacionFinanciera,
  },
];

export const ROUTER_DOM_ROUTES = [
  {
    ruta: RUTAS.catalogo,
    componente: Catalogo,
  },
  {
    ruta: RUTAS.producto,
    componente: Producto,
  },
  {
    ruta: RUTAS.carrito,
    componente: CarritoPage,
  },
  {
    ruta: RUTAS.contacto,
    componente: Contacto,
  },
  {
    ruta: RUTAS.agradecimiento,
    componente: AgradecimientoContacto,
  },
  {
    ruta: RUTAS.cripto,
    componente: Crypto,
  },
];
