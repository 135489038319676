import { useContext } from "react";
import "./CenterNav.css";
import { Link } from "react-router-dom";
import { Box, Hidden } from "@mui/material";
import { AppContext } from "../../../context/AppContext";
import MenuReconocimiento from "../../Menu/MenuDropDown/Index";
import ES from "../../../common/language/shared/es.json";
import EN from "../../../common/language/shared/en.json";
import useLanguage from "../../../common/hooks/useLanguage";
import { CRIPTO_SUB_MENU, RECONOCIMIENTO_SUB_MENU, RUTAS } from "../../../common/constants/rutas";
import { PERMISOS_CODIGOS } from "../../../common/constants/constants";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import SportsSoccerOutlinedIcon from "@mui/icons-material/SportsSoccerOutlined";
import MenuDropdown from "../../Menu/MenuDropDown/Index";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";


const CenterNav = () => {
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });

  return (
    <>
      <Hidden smDown={true}>
        <Box fontSize={14} className="header-nav center-nav">
          {appContext?.user ? (
            <>
              {appContext.permisos?.find((el) => el.codigo === PERMISOS_CODIGOS.catalogoGiftCard) && (
                <Link to={RUTAS.catalogo} style={{ display: 'flex', alignItems: 'center' }}>
                  <RedeemOutlinedIcon sx={{ fontSize: '20px' }} />
                  {dictionary?.header.giftCards}
                </Link>
              )}
              {appContext.permisos?.find(
                (el) => el.codigo === PERMISOS_CODIGOS.criptomonedas
              ) && (
                <MenuDropdown
                  buttonText={dictionary?.header.criptomonedas}
                  buttonIcon={<CurrencyBitcoinOutlinedIcon sx={{ fontSize: "20px" }} />}
                  subMenu={CRIPTO_SUB_MENU}
                />
              )}
              <Link
                to={RUTAS.copa}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <SportsSoccerOutlinedIcon sx={{ fontSize: '20px' }} />
                Copa
              </Link>
              {appContext.permisos?.find(
                (el) =>
                  el.codigo === PERMISOS_CODIGOS.reconocimientosEnDashboardWeb
              ) &&  (
                <MenuDropdown
                  buttonText={dictionary?.header.reconocimientos}
                  buttonIcon={<EmojiEventsOutlinedIcon sx={{ fontSize: "20px" }} />}
                  subMenu={RECONOCIMIENTO_SUB_MENU}
                />
              )}
            </>
          ) : (
            <>
              <Link to={RUTAS.catalogo} style={{ display: 'flex', alignItems: 'center' }}>
                <RedeemOutlinedIcon sx={{ fontSize: '20px' }} />
                {dictionary?.header.giftCards}
              </Link>
              <Link to={RUTAS.cripto} style={{ display: 'flex', alignItems: 'center' }}>
                <CurrencyBitcoinOutlinedIcon sx={{ fontSize: '20px' }} />
                {dictionary?.header.criptomonedas}
              </Link>
              <Link
                to={RUTAS.copa}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <SportsSoccerOutlinedIcon sx={{ fontSize: '20px' }} />
                Copa
              </Link>
            </>
          )}
        </Box>
      </Hidden>
    </>
  );
};

export default CenterNav;
